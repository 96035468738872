// data
const mianInfo = {
  tel: '22 400 66 33',
  mail: 'hello@kimidori.pl',
  address: 'Bernardyńska 16B',
  addressGoogle: 'https://goo.gl/maps/qsTcrZNZLjdFZd3L7',
  instagram: 'https://www.instagram.com/kimidorigreensushi/',
  facebook: 'https://www.facebook.com/kimidorigreensushi',
  open: [
    {
      day: 'PN-PT',
      hours: '12:00 - 22:00',
    },

    {
      day: 'SB-ND',
      hours: '11:00 - 22:00',
    },
  ],
}

export default mianInfo
