import React, { useEffect, useState } from 'react'
import ContextConsumer from 'src/layouts/Context.js'
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll'

import { Link } from 'gatsby'
import styled from 'styled-components'
import distances from 'src/assets/styles/distances.js'
import typographySizes from 'src/assets/styles/typographySizes.js'
import colors from 'src/assets/styles/colors.js'
import mediaQuery from 'src/assets/styles/mediaQuery.js'
import Logo from '/src/assets/svg/kimidori-logo.svg'
import LogoPink from '/src/assets/svg/pink_vegan_baisc_logo_color_.svg'
import Fb from '/src/assets/svg/fb.svg'
import Insta from '/src/assets/svg/insta.svg'
import Hamburger from '/src/components/global/Hamburger.js'

import mianInfo from 'src/assets/data/ mianInfo.js'
import menuPDF from 'src/static/menu.pdf'

const NavWrapper = styled.nav`
  padding: ${distances.s}rem;
  padding-bottom: 0;
  position: fixed;
  width: calc(100vw - ${distances.s * 2}rem);
  display: flex;
  justify-content: space-between;
  z-index: 100;
  @media (max-width: ${mediaQuery.tablet}) {
    padding: ${distances.xs}rem;
    width: calc(100vw - ${distances.xs * 2}rem);
    height: 70rem;
    /* background-color: ${colors.bright}; */
  }
`

const StyledLogo = styled.img`
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  /* height: 125rem; */
  height: 70rem;
  /* margin-right: 40rem; */
  /* &.scrolled {
    height: 80rem;
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  } */
  @media (max-width: ${mediaQuery.tablet}) {
    height: 100rem;
  }

  /* &.scrolled {
    height: 70rem;
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  } */
`

const RightWrapper = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-start;

  padding: 10rem;
  position: relative;
  top: -10rem;
  right: -10rem;
  border-radius: 10rem;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  border: solid 1rem transparent;
  :hover {
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    background-color: ${colors.white};
    border: solid 1rem ${colors.darkGreen};
  }

  @media (max-width: ${mediaQuery.tablet}) {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    padding: 0rem;
    padding-top: 100rem;
    height: calc(100vh - 100rem);
    width: 100vw;
    background-color: ${colors.bright};

    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    transform: translateX(-100%);
    z-index: -1;
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);

    :hover {
      border: solid 1rem transparent;
      background-color: ${colors.bright};
    }

    &.open {
      transform: translateX(-0%);
      transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }
  }
`

const LinksWrapper = styled.ul`
  display: flex;
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 2vh;
  }
`

const StyledLink = styled(ScrollLink)`
  font-size: ${typographySizes.s}rem;
  padding: 5rem 15rem;
  text-decoration: none;
  position: relative;
  cursor: pointer;

  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  ::after {
    content: '';
    position: absolute;
    bottom: 10rem;
    left: 15rem;
    width: 0%;
    height: 1rem;
    background-color: ${colors.darkGreen};
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
  :hover {
    transform: translateY(-5rem);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
  :hover ::after {
    width: calc(100% - 30rem);
    transform: translateY(5rem);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }

  &.active {
    transform: translateY(-5rem);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }

  &.active::after {
    width: calc(100% - 30rem);
    transform: translateY(5rem);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }

  @media (max-width: ${mediaQuery.tablet}) {
    padding: 1vh 15rem;
    font-size: ${typographySizes.m}rem;
  }
`

const StyledLinkApp = styled(Link)`
  font-size: ${typographySizes.s}rem;
  padding: 3rem 15rem 5rem 15rem;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  color: ${colors.white};
  background-color: ${colors.darkGreen};
  border-radius: 9rem;
  margin-left: 10rem;
  margin-right: 10rem;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  ::after {
    content: '';
    position: absolute;
    bottom: 10rem;
    left: 15rem;
    width: 0%;
    height: 1rem;
    background-color: ${colors.darkGreen};
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
  :hover {
    transform: translateY(-5rem);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
  :hover ::after {
    width: calc(100% - 30rem);
    transform: translateY(5rem);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }

  &.active {
    transform: translateY(-5rem);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }

  &.active::after {
    width: calc(100% - 30rem);
    transform: translateY(5rem);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }

  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.m}rem;
    margin-top: 15rem;
    margin-bottom: 30rem;
  }
`

const StyledLinkA = styled.a`
  font-size: ${typographySizes.s}rem;
  padding: 5rem 15rem;
  text-decoration: none;
  position: relative;
  cursor: pointer;

  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  ::after {
    content: '';
    position: absolute;
    bottom: 10rem;
    left: 15rem;
    width: 0%;
    height: 1rem;
    background-color: ${colors.darkGreen};
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
  :hover {
    transform: translateY(-5rem);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
  :hover ::after {
    width: calc(100% - 30rem);
    transform: translateY(5rem);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }

  &.active {
    transform: translateY(-5rem);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }

  &.active::after {
    width: calc(100% - 30rem);
    transform: translateY(5rem);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }

  @media (max-width: ${mediaQuery.tablet}) {
    padding: 1vh 15rem;
    font-size: ${typographySizes.m}rem;
  }
`

const SocialWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10rem;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: 10vh;
  }
`

const StyledSocialLink = styled(Link)`
  font-size: ${typographySizes.s}rem;
  padding: 0rem 7.5rem;
  align-items: center;
  display: flex;
  @media (max-width: ${mediaQuery.tablet}) {
    padding: 10rem 15rem;
    width: 40rem;
    img {
      width: 100%;
    }
  }
`

const Nav = props => {
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line global-require
    require('smooth-scroll')('a[href*="#"]')
  }
  const isBrowser = typeof window !== 'undefined'

  const [navScrolled, setNavScrolled] = useState(false)

  useEffect(() => {
    if (isBrowser) {
      window.onscroll = function (e) {
        let scrollTop =
          window.pageYOffset ||
          (
            document.documentElement ||
            document.body.parentNode ||
            document.body
          ).scrollTop

        scrollTop > 150 ? setNavScrolled(true) : setNavScrolled(false)
      }
    }
  }, [])
  useEffect(() => {
    const script = document.createElement('script')

    script.src = 'https://www.fbgcdn.com/embedder/js/ewm2.js'
    script.async = true
    script.defer = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, ['https://www.fbgcdn.com/embedder/js/ewm2.js'])

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://cdn.upmenu.com/media/upmenu-widget.js'
    script.async = true
    script.defer = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <>
      <NavWrapper
        data-sal="slide-down"
        data-sal-delay="700"
        data-sal-easing="ease"
        data-sal-duration="500"
      >
        <StyledLogo src={Logo} className={navScrolled && 'scrolled'} />

        <Hamburger />
        <ContextConsumer>
          {({ data, set }) => (
            <RightWrapper className={data.navOpen && 'open'}>
              <LinksWrapper>
                <StyledLink
                  onClick={() => {
                    set({ navOpen: false })
                  }}
                  to="o-nas"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  o nas
                </StyledLink>
                <StyledLink
                  onClick={() => {
                    set({ navOpen: false })
                  }}
                  to="promocje"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  promocje
                </StyledLink>
                <StyledLink
                  onClick={() => {
                    set({ navOpen: false })
                  }}
                  to="dostawa"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  dostawa
                </StyledLink>

                <StyledLink
                  onClick={() => {
                    set({ navOpen: false })
                  }}
                  to="kontakt"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  kontakt
                </StyledLink>
                <StyledLinkA
                  href={menuPDF}
                  target="_blank"
                  smooth={true}
                  offset={-80}
                  duration={500}
                >
                  pobierz pełne menu
                </StyledLinkA>
                <StyledLinkA
                  onClick={() => {
                    set({ navOpen: false })
                  }}
                  activeClass="active"
                  spy={true}
                  href="https://www.kimidori.pl/menu"
                >
                  zamów online
                </StyledLinkA>
              </LinksWrapper>
              <SocialWrapper>
                <StyledSocialLink to={mianInfo.facebook} target="_blank">
                  <img src={Fb} />
                </StyledSocialLink>
                <StyledSocialLink to={mianInfo.instagram} target="_blank">
                  <img src={Insta} />
                </StyledSocialLink>
              </SocialWrapper>
            </RightWrapper>
          )}
        </ContextConsumer>
        <script>
          {`
          window.upmenuSettings = {
            id: "a20dc498-7221-11ec-9695-525400080621",
            page_id: "booking"
          };
        `}
        </script>
      </NavWrapper>
      <div id="upmenu-widget"></div>
    </>
  )
}

export default Nav
